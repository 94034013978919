import React, { useState } from 'react';
import axios from 'axios';
import './style.css'
import FooterArea from 'components/blocks/FooterArea/index';
import { windowGlobal } from '../../utils/helperFunction';

const Login = () => {
  const [slug, setSlug] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleSlugValidate = () => {
    axios.post('//api.galam.co/v1/schools/validate', {
      slug
    })
    .then((response) => {
      // handle success
      windowGlobal.location.href = `https://${slug}.galam.co`
    })
    .catch((error) => {
      if (error.response.data) {
        setErrorMsg(error.response.data.error)
      }
      console.log(error);
    })
  }

  return (
    <React.Fragment>
    <section className="welcome-page sec-padding pb-150px p-relative o-hidden bg-gray h-auto">
      <div className="container">
        <div className="row welcome-text sec-padding flex-center">
          <div className="col-md-6 mb-50px">
            <img alt="img" src="/images/login2.png" className="ml-auto mr-auto" />
          </div>
          <div className="col-md-6">
            <h1>تسجيل الدخول</h1>
            {/* <p className="mb-50px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p> */}
            <div id="log-in" className="mt-30px mb-20px">
              <small style={{ color: '#ff7762'}}>{errorMsg}</small>
              <div className="form-group p-relative">
                <input onChange={e => setSlug(e.target.value)} value={slug} type="text" placeholder="أسم الحضانة" required className="d-block mb-20px" />
                <i class="fa fa-link fs-20 color-blue p-absolute"></i>
              </div>
              <button
                onClick={handleSlugValidate}
                className="main-btn btn-3 before-gray">إرسال</button>
            </div>
            {/* <a href="register-page.html" className="float-left mb-10px">Not a member? Sign up</a>
            <a href="lost-password-page.html" className="float-right">Forget my password</a> */}
          </div>
        </div>
      </div>
      <div className="pattern p-absolute">
      </div>
    </section>
    <FooterArea />
    </React.Fragment>
  )
}

export default Login;