import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import LoginTemplates from 'templates/Login';

const Login = () => {
  return (
    <Layout>
      <SEO title="تسجيل الدخول" keywords={[`الدخول`, `تسجيل`, `قلم`, `برنامج قلم`, `الحضانة`]} />
      <LoginTemplates />
    </Layout>
  )
}

export default Login;